<template>
  <div class="index_block">
    <Slider />
    <div class="courses_sort_filter_block">
      <div class="courses_sort_filter_back">
        <div class="courses_sort_filter_container">
          <div class="courses_filter_block">
            <div class="courses_filter_container">
              <div class="courses_filter_label">{{ $t('Sort_and_filter') }}</div>
              <div class="courses_list_sorting">
                <div
                  class="courses_list_sorting_label"
                  :class="{ active: showSortingBlock }"
                  @click="showSortingBlock = !showSortingBlock"
                >{{ $t('Sorting') }}</div>
                <div class="courses_list_radio_block" v-show="showSortingBlock">
                  <div class="courses_radio_item" :class="{ active: type === 'newest'}">
                    <input v-model="type" type="radio" name="sort" value="newest" id="newest" class="courses_list_radio" />
                    <label for="newest" class="radio_label">{{ $t('New_ones_first') }}</label>
                  </div>
                  <div class="courses_radio_item" :class="{ active: type === 'popular'}">
                    <input v-model="type" type="radio" name="sort" value="popular" id="popular" class="courses_list_radio" />
                    <label for="popular" class="radio_label">{{ $t('Popular') }}</label>
                  </div>
                  <div class="courses_radio_item" :class="{ active: type === 'rating'}">
                    <input v-model="type" type="radio" name="sort" value="rating" id="rating" class="courses_list_radio" />
                    <label for="rating" class="radio_label">{{ $t('By_rating') }}</label>
                  </div>
                </div>
              </div>
              <div class="courses_list_sorting">
                <div
                  class="courses_list_sorting_label"
                  :class="{ active: showCoursesDurationBlock }"
                  @click="showCoursesDurationBlock = !showCoursesDurationBlock"
                >{{ $t('Course_duration') }}</div>
                <div class="courses_list_radio_block" v-show="showCoursesDurationBlock">
                  <div class="courses_radio_item" :class="{ active: duration === 'all'}">
                    <input v-model="duration" type="radio" value="all" name="filter" id="all" class="courses_list_radio" />
                    <label for="all" class="radio_label">Все</label>
                  </div>
                  <div class="courses_radio_item" :class="{ active: duration === '8'}">
                    <input v-model="duration" type="radio" value="8" name="filter" id="8" class="courses_list_radio" />
                    <label for="8" class="radio_label">8</label>
                  </div>
                  <div class="courses_radio_item" :class="{ active: duration === '36'}">
                    <input v-model="duration" type="radio" value="36" name="filter" id="36" class="courses_list_radio" />
                    <label for="36" class="radio_label">36</label>
                  </div>
                  <div class="courses_radio_item" :class="{ active: duration === '40'}">
                    <input v-model="duration" type="radio" name="filter" value="40" id="40" class="courses_list_radio" />
                    <label for="40" class="radio_label">40</label>
                  </div>
                  <div class="courses_radio_item" :class="{ active: duration === '72'}">
                    <input v-model="duration" type="radio" name="filter" value="72" id="72" class="courses_list_radio" />
                    <label for="72" class="radio_label">72</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="courses_list_wrapper">
              <div v-if="courses.length" class="courses_list">
                <div
                  class="cursor-pointer courses_list_item"
                  v-for="course in courses"
                  @click="openCourse(course.slug)">
                  <div class="courses_list_item_desc">
                    <div class="courses_list_item_img">
                      <img :src="course['preview_image']" alt="" style="max-width: 100%;">
                    </div>
                    <div class="courses_list_item_desc_text">
                      <div class="courses_list_item_title">{{ course.title }}</div>
                      <div class="courses_list_item_date"><b>{{ course['enrollments'] }}</b> {{ $t('Registration_for_the_course') }}</div>
                      <!-- <div class="courses_list_item_rating">
                        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.9033 6.68223C15.6841 6.11137 15.2803 4.875 14.3131 4.875H10.9488C10.5139 4.875 10.1288 4.59388 9.99635 4.17962L8.95247 0.91566C8.65595 -0.0115135 7.34405 -0.0115147 7.04753 0.915659L6.00365 4.17962C5.87116 4.59388 5.4861 4.875 5.05117 4.875H1.65569C0.691592 4.875 0.285783 6.10488 1.06056 6.67863L3.83245 8.73133C4.17572 8.98554 4.31963 9.42971 4.19065 9.83692L3.15075 13.12C2.85798 14.0444 3.92001 14.8027 4.6992 14.2256L7.40488 12.222C7.75843 11.9601 8.24157 11.9601 8.59512 12.222L11.2889 14.2168C12.0693 14.7947 13.1323 14.0334 12.8365 13.1086L11.782 9.81146C11.6509 9.40151 11.7968 8.95365 12.1443 8.69961L14.9033 6.68223Z" fill="#16C95D"/>
                        </svg>
                        4.9
                      </div> -->
                    </div>
                  </div>
                  <button @click="openCourse(course.slug)" class="courses_list_item_more">{{ $t("more")}}</button>
                </div>
              </div>
              <div v-if="loading" class="courses_loading">
                <i class="el-icon-loading" :color="'red'"></i>
              </div>
              <div class="courses_pagination">
                <MyPagination
                  :totalPages="totalPages"
                  :total="total"
                  :perPage="perPage"
                  :currentPage="currentPage"
                  @pagechanged="onPageChange"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Slide } from 'vue-carousel';
import Slider from "@/views/Home/Slider.vue";
import MyPagination from "@/components/MyPagination.vue";

export default {
  components: {
    MyPagination,
    Slider,
    Slide,
  },
  data() {
    return {
      loading: false,
      courses: [],
      apiRoot: API_ROOT,
      showSortingBlock: true,
      showCoursesDurationBlock: true,
      currentPage: 1,
      perPage: 12,
      duration: 'all',
      type: 'newest',
      total: 0,
      totalPages: 0,
      showFilter: false,
      backUrl: window.API_ROOT + '/images/goods/',
      noPhoto: require('@/assets/images/no_photo.png'),
    }
  },
  methods: {
    getCourses() {
      this.loading = true
      this.courses = []
      const apiRoot = API_ROOT
      const url = new URL(`${apiRoot}/api/courses`)
      url.searchParams.set('page', this.currentPage)
      url.searchParams.set('type', this.type)
      url.searchParams.set('duration', this.duration)
      if (this.duration === 'all') url.searchParams.delete('duration')
      this.$http.get(url.href)
        .then(({ body }) => {
          this.courses = body.data
          this.total = body.meta.total
          this.perPage = body.meta['per_page']
          this.totalPages = body.meta['last_page']
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.loading = false
        })
    },
    openCourse(slug) {
      this.$router.push({ name: 'course-details', params: { slug } }).catch(() => {})
    },
    setAltImg(event) {
      event.target.src = this.noPhoto
    },
    mainImage(item) {
      for (let i = 0; i < item.gallery.length; i++) {
        if (item.gallery[i].is_main === 1) {
          return item.gallery[i].image
        }
      }
    },
    onPageChange(page) {
      this.currentPage = page;
    }
  },
  mounted() {
    this.getCourses()
  },
  watch: {
    duration: function () {
      this.getCourses()
    },
    type: function () {
      this.getCourses()
    },
  }
}
</script>
<style>
.btn-result {
  color: #fff !important;
  background-color: #007bff !important;
  border-color: #007bff !important;
  max-width: 252.7px !important;
  margin-top: 1rem !important;
  display: block !important;
}

@media (max-width: 991px) {
  .btn-result {
    margin: auto;
  }
}

.slide-pre,
.slide-next {
  position: absolute;
  top: calc(50% - 25px);
  border: none;
  background-color: transparent;
}

.slide-pre:active,
.slide-pre:hover,
.slide-next:active,
.slide-next:hover {
  outline: none;
  cursor: pointer;
}

.slide-next {
  right: -50px;
}

.slide-pre {
  left: -50px;
}

</style>
