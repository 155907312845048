<template>
  <ul class="pagination" v-if="totalPages > 1">
    <li class="pagination-item">
      <button
          type="button"
          @click="onClickPreviousPage"
          :disabled="isInFirstPage"
          class="pagination_btn_prev"
      >
        <span>Предыдущая страница</span>
      </button>
    </li>

    <div style="display: flex">
      <li
          v-for="page in pages"
          :key="page.name"
          v-if="page.name"
      >
        <button
            type="button"
            @click="onClickPage(page.name)"
            :disabled="page.isDisabled"
            :class="{ active: isPageActive(page.name) }"
            class="pagination-item-vis"
        >
          {{ page.name }}
        </button>
      </li>
      <li class="pagination-item">
        <button
            type="button"
            @click="onClickLastPage"
            :disabled="isInLastPage"
            class="pagination-item-vis-last"
        >
          ...
        </button>
      </li>
    </div>

    <li class="pagination-item">
      <button
          type="button"
          @click="onClickNextPage"
          :disabled="isInLastPage"
          class="pagination_btn_next"
      >
        <span>Следующая страница</span>
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'MyPagination',
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3,
    },
    totalPages: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  },
  computed: {
    startPage() {
      if (this.currentPage === 1) {
        return 1;
      }

      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons + 1;
      }

      return this.currentPage - 1;
    },
    pages() {
      const range = [];

      for (
          let i = this.startPage;
          i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
          i++
      ) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage
        });
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit('pagechanged', 1);
    },
    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit('pagechanged', page);
    },
    onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1);
    },
    onClickLastPage() {
      this.$emit('pagechanged', this.totalPages);
    },
    isPageActive(page) {
      return this.currentPage === page;
    }
  }
};
</script>

<style scoped>

.pagination {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.pagination-item {
  display: inline-block;
}

.pagination-item-vis {
  width: 32px;
  height: 32px;
  background: none;
  border-radius: 50%;
  border: none;
  margin-left: 10px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #737373;
}

.pagination-item-vis-last {
  width: 32px;
  height: 32px;
  background: none;
  border-radius: 50%;
  border: none;
  margin-left: 10px;
  padding-bottom: 10px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #737373;
}

.pagination-item-vis:hover {
  background-color: #fff;
  color: #305AA6;
  cursor: pointer;
}

.pagination-item-vis-last:hover {
  background-color: #fff;
  color: #305AA6;
  cursor: pointer;
}

.active {
  background-color: #007BFC;
  color: #ffffff;
  margin-left: 10px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}
.active:hover{
  background-color: #007BFC;
  color: #ffffff;
}

.pagination_btn_prev {
  background: none;
  border: none;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #737373;
  padding-left: 30px;
  position: relative;

  cursor: pointer;
}

.pagination_btn_prev::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  left: 0;
  top: 2px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../assets/images/pagination_arrow_left.svg");
  transform: scale(-1, 1);
}

.pagination_btn_next {
  background: none;
  border: none;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #737373;
  padding-right: 30px;
  position: relative;

  cursor: pointer;
}

.pagination_btn_next::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  right: 0;
  top: 2px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../assets/images/pagination_arrow_left.svg");
}

@media (max-width: 900px) {

  .pagination-item {
    display: block;
  }

  .pagination_btn_prev span {
    display: none;
  }

  .pagination_btn_next span {
    display: none;
  }

  .pagination_btn_prev::after {
    top: -14px;
  }

  .pagination_btn_next::after {
    top: -14px;
  }

  .pagination-item-vis:hover {
    background-color: #fff;
    color: #ffffff;
  }

  .pagination-item-vis-last:hover {
    background-color: #fff;
    color: #ffffff;
  }
}

</style>
