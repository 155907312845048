<template>
  <div class="slider_block">
    <div class="slider_container">
      <div class="slider_arrow_left" @click="prevPage"/>
      <div class="slider_arrow_right" @click="nextPage"/>
      <carousel
          :per-page="1"
          navigationNextLabel="next"
          paginationActiveColor="#FFFFFF"
          paginationColor="rgba(255, 255, 255, 0.17)"
          :navigateTo="manualNavigation"
      >
        <slide v-for="course in courses" class="slider_slide">
          <div class="slider_label">{{ course.title }}</div>
          <div class="slider_text">
<!--            <div v-if="course['featured'] == '1'" class="crop-height">{{$t('Popular')}}</div>-->
            <div class="slider_text" v-html="course['short_detail']"></div>
            <router-link :to="{ name: 'course-details', params: { slug: course.slug } }" class="slider_enroll"><span>{{$t("show_more")}}</span></router-link>
          </div>
        </slide>
<!--        <slide class="slider_slide">-->
<!--          <div class="slider_label">-->
<!--            Бесплатный курс-тренажер-->
<!--          </div>-->
<!--          <div class="slider_text">-->
<!--            <span class="crop-height">Научись с нуля продажам и зарабатывай в месяц от</span>-->
<!--          </div>-->
<!--          <div class="slider_price">-->
<!--            <img src="@/assets/images/slider-price.svg" alt="">-->
<!--          </div>-->
<!--          <a href="/courses" class="slider_enroll">-->
<!--            <span>{{ $t('Learn_free') }}</span>-->
<!--          </a>-->
<!--        </slide>-->
<!--        <slide class="slider_slide">-->
<!--          <div class="slider_label">-->
<!--            Бесплатный курс-тренажер-->
<!--          </div>-->
<!--          <div class="slider_text crop-height">-->
<!--            <span class="crop-height">Использование компьютера требует специальных навыков. Людей, достигших в этом мастерства, иногда называют-->
<!--            «хакерами». Обычно хакер – это человек, который создаёт программное обеспечение на специальном компьютерном-->
<!--            языке. Но слово «хакер» также применимо к человеку, который пытается украсть информацию из компьютерных-->
<!--            систем.</span>-->
<!--          </div>-->
<!--          <a href="/courses" class="slider_enroll">-->
<!--            <span>{{ $t('Sign_up_for_a_courses') }}</span>-->
<!--          </a>-->
<!--        </slide>-->
      </carousel>
    </div>

  </div>
</template>

<script>
import {Carousel, Slide} from "vue-carousel";

export default {
  name: "SliderMainHome",
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      courses: [],
      manualNavigation: 0
    }
  },
  mounted() {
    this.getFeaturedCourses();
  },
  methods: {
    getFeaturedCourses() {
      let apiRoot = API_ROOT
      this.$http.get(`${apiRoot}/api/courses?featured=true`)
        .then(res => {
          this.courses = res.body.data
        })
    },
    nextPage() {
      if (this.manualNavigation >= 1) {
        this.manualNavigation = 0
      } else this.manualNavigation = this.manualNavigation + 1
    },
    prevPage() {
      if (this.manualNavigation <= 0) {
        this.manualNavigation = 1
      } else this.manualNavigation = this.manualNavigation - 1
    },
  }
}
</script>

<style scoped>
.crop-height {
  display: -webkit-box !important;
  -webkit-line-clamp: 6 !important;
  -webkit-box-orient: vertical !important;
  overflow-y: hidden !important;
}
</style>
